import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Grid, Margin, PageWrapper, Section } from "@components/atoms"
import { GridItem, JobItem } from "@components/molecules"
import { TransitionMask } from "@components/organisms"
import { Title } from "@components/molecules/GridItem"
import { PageProps } from "@types"

const StudioPage = ({
  data: {
    sanityPage: { title, description, gridItems, extraHomeFields },
  },
}: PageProps) => {
  const parsedTitle = "Franklyn: " + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  const { jobs } = extraHomeFields
  return (
    <PageWrapper>
      <Helmet {...{ meta }} title={parsedTitle}>
        <title>{parsedTitle}</title>
      </Helmet>
      <Margin>
        <StyledGrid>
          {/* <Introduction>
            <div>We create</div><div>brands</div><div>with a soul.</div>
          </Introduction> */}
          {gridItems
            .filter(p => !!p)
            .map(item => (
              <GridItem key={item._key} {...item}>
                {item.description && (
                  <StyledTitle>
                    <Name>{item.description}</Name>
                  </StyledTitle>
                )}
              </GridItem>
            ))}
        </StyledGrid>
      </Margin>

      {!!jobs && jobs.length > 0 && (
        <Margin>
          <Section title="Careers">
            <JobsGrid>
              {jobs.map((job, i) => (
                <JobItem {...job} key={i} />
              ))}
            </JobsGrid>
          </Section>
        </Margin>
      )}
      <TransitionMask />
    </PageWrapper>
  )
}

const StyledGrid = styled(props => <Grid {...props} />)`
  @media only screen and (min-width: 744px) {
    padding-top: 0px;
  }
`

const StyledTitle = styled(props => <Title {...props} />)`
  margin-top: 2em;
`

const Introduction = styled.div`
  grid-column: 1 / span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 3 / span 8;
  }
  font-family: "HCo Sentinel SSm", serif;
  font-weight: 300;
  font-size: 8rem;
  letter-spacing: 0;
  line-height: 1.1em;
  text-align: center;
  margin-top: -0.25em;
  @media only screen and (min-width: 744px) {
    font-size: 12rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 10.5rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 10vw;
  }
  > div {
    line-height: 1.1em;
  }
`

const Name = styled.h3`
  display: block;
  margin: auto;
  text-align: center;
`

const JobsGrid = styled(props => <Grid {...props} />)`
  padding: 0px !important;
  @media only screen and (max-width: 743px) {
    display: block;
  }
  @media only screen and (min-width: 1200px) {
    grid-column-gap: 40px;
  }
`

export const query = graphql`
  query StudioPageQuery {
    sanityPage(slug: { current: { eq: "studio" } }) {
      ...pageFields
      extraHomeFields {
        jobs {
          post {
            title
            description
            slug {
              current
            }
          }
        }
      }
    }
  }
`

export default StudioPage
